const mainMenuData = [
  { name: "Inicio", link: "/" },
  { name: "Nosotros", link: "/nosotros" },
  { name: "Talleres", link: "/talleres-danza-aerea-circo" },
  { name: "Contacto", link: "/contacto" },
  //{name:'Wiki Circo', link:'/wiki-circo'},
]
const wikiMenuData = [
  //{name:'Curioso Circo', link:'/'},
  { name: "Todos", link: "/wiki-circo" },
  { name: "Malabares", link: "/wiki-circo/malabares" },
  { name: "Acroyoga", link: "/wiki-circo/acroyoga" },
  { name: "Standing Acro", link: "/wiki-circo/standing" },
  { name: "Mapa Circo", link: "/wiki-circo/mapa-circo" },
]
const ccMenu = [
  { name: "Curioso Circo", link: "/" },
  { name: "Wiki Circo", link: "/wiki-circo" },
]
// module.exports.mainMenuData = mainMenuData
// module.exports.wikiMenuData = wikiMenuData
// module.exports.ccMenu = ccMenu

const levels = ["facil", "intermedio", "difícil", "muy difícil"]
const skills = ["malabares", "danzaaerea", "cyrwheel", "acroyoga", "standing"]
// module.exports.levels = levels
// module.exports.skills = skills

//////////////////
// QUERIES
//////////////////
// FRAGMENTS ALTERNATIVE
const postBaseQuery = `
title
content
image {
	url
	formats{
		medium{
			url
		}
	}
	image {
		childImageSharp {
			gatsbyImageData(layout: CONSTRAINED)
		}
	}
}
tags {
	name
}
date
`
const tricksBaseQuery = `
${postBaseQuery}
name
aka
videourl
level{
	name
}
skill
type
`
const duoPosesBaseQuery = `
${tricksBaseQuery}
numPeople
`
// transitions{
// 	pose
// }

// POST QUERIES
const allPostsQuery = `
{
	allMongodbCuriosowebPosts( sort: {order: DESC, fields: date})
    {
		nodes {
			${postBaseQuery}
		}
    }
}
`
const allPostsGroupedByTagQuery = `
{
	allMongodbCuriosowebPosts( sort: {order: DESC, fields: date})
    {
		group(field: tags___name) {
			totalCount
			fieldValue
			nodes {
				${postBaseQuery}
			}
		}
    }
}
`
const allPostsQueryGroupedByTag = `
{
	allMongodbCuriosowebPosts( sort: {order: DESC, fields: createdAt}) {
		group(field: tags___name) {
			totalCount
			fieldValue
			nodes {
				title
			}
		}
	}
}
`

// module.exports.allPostsQuery = allPostsQuery
// module.exports.allPostsGroupedByTagQuery = allPostsGroupedByTagQuery
// module.exports.allPostsQueryGroupedByTag = allPostsQueryGroupedByTag

// DUO POSES QUERIES
const duoPosesGroupedByLevelQuery = `
{
	allMongodbCuriosowebDuoPoses( sort: {order: DESC, fields: title})
	{
		group(field: level___name) {
			totalCount
			fieldValue
			nodes {
				${duoPosesBaseQuery}
			}
		}
	}
}
`
const duoPosesGroupedBySkillQuery = `
{
	allMongodbCuriosowebDuoPoses( sort: {order: DESC, fields: title})
	{
		group(field: skill) {
			totalCount
			fieldValue
			nodes {
				${duoPosesBaseQuery}
			}
		}
	}
}
`
const duoPosesQuery = `
{
	allMongodbCuriosowebDuoPoses( sort: {order: ASC, fields: title})
    {
		group(field: type) {
			totalCount
			fieldValue
			nodes {
				${duoPosesBaseQuery}
			}
		}
		
		nodes{
			${duoPosesBaseQuery}
		}
		
    }
}
`
// module.exports.duoPosesGroupedByLevelQuery = duoPosesGroupedByLevelQuery
// module.exports.duoPosesGroupedBySkillQuery = duoPosesGroupedBySkillQuery
// module.exports.duoPosesQuery = duoPosesQuery

const transitionsQuery = `
{
	allMongodbCuriosowebTransitions {
		nodes {
			name
			poseFrom {
				name
        }
        poseTo {
          name
        }
        video
        canGoBack
      }
    }
  }
`
// module.exports.transitionsQuery = transitionsQuery

// TRICK QUERIES
const tricksQuery = `
{
	allMongodbCuriosowebTricks( sort: {order: DESC, fields: date})
    {
		nodes {
			${tricksBaseQuery}
		}
    }
}
`
const allTricksGroupedBySkillQuery = `
{
	allMongodbCuriosowebTricks( sort: {order: DESC, fields: date})
    {
		group(field: skill) {
			totalCount
			fieldValue
			nodes {
				${tricksBaseQuery}
			}
		}
    }
}
`
const allTricksGroupedByLevelQuery = `
{
	allMongodbCuriosowebTricks( sort: {order: DESC, fields: date})
    {
		group(field: level___name) {
			totalCount
			fieldValue
			nodes {
				${tricksBaseQuery}
			}
		}
    }
}
`

// module.exports.tricksQuery = tricksQuery
// module.exports.allTricksGroupedBySkillQuery = allTricksGroupedBySkillQuery
// module.exports.allTricksGroupedByLevelQuery = allTricksGroupedByLevelQuery

// OTHER QUERIES
const tagsQuery = `
{
	allMongodbCuriosowebTags {
		nodes{
			name
		}
	}
}
`
const missingImgQuery = `
{
	file(relativePath: {eq: "clavas.jpg"}) {
		relativePath
		childImageSharp {
			gatsbyImageData(layout: CONSTRAINED)
		}
	}
}
`
const markdownQueryPages = `
{
	allMarkdownRemark(
		filter: {fileAbsolutePath: {regex: "/pages/"}}
		sort: { order: DESC, fields: [frontmatter___date] }
		limit: 1000
		) {
			edges {
				node {
					frontmatter {
						path
					}
				}
			}
		}
	}	  
`
const markdownQueryPosts = `
{
	allMarkdownRemark(
		filter: {fileAbsolutePath: {regex: "/blog/"}}
		sort: { order: DESC, fields: [frontmatter___date] }
		limit: 1000
		) {
			edges {
				node {
					frontmatter {
						path
					}
				}
			}
		}
	}	  
	`
const notionQuery = `
{
	allNotionContent {
		edges {
			node {
				internal {
					description
					content
				}
				id
				contentType
			}
		}
	}
}
`
// module.exports.tagsQuery = tagsQuery
// module.exports.missingImgQuery = missingImgQuery
// module.exports.markdownQuery = markdownQuery
// module.exports.notionQuery = notionQuery

export {
  mainMenuData,
  wikiMenuData,
  ccMenu,
  levels,
  skills,
  allPostsQuery,
  allPostsGroupedByTagQuery,
  allPostsQueryGroupedByTag,
  duoPosesQuery,
  duoPosesGroupedByLevelQuery,
  duoPosesGroupedBySkillQuery,
  transitionsQuery,
  tricksQuery,
  allTricksGroupedBySkillQuery,
  allTricksGroupedByLevelQuery,
  tagsQuery,
  missingImgQuery,
  markdownQueryPages,
  markdownQueryPosts,
  notionQuery,
}
