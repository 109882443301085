import React from "react"
import { Link } from "gatsby"

const Menu = ({ items, addMobile = true, className = "" }) => {
  items = items ? items : []
  var menu = items.map((val, key) => {
    var cn = val.selected ? "active" : ""
    return (
      <div key={key}>
        <Link to={val.link} className={cn}>
          {val.name}
        </Link>
      </div>
    )
  })

  menu = <div className={`header-menu ${className}`}>{menu}</div>

  var menuMobile = ""

  if (addMobile) {
    menuMobile = items.map((val, key) => {
      return (
        <li key={key}>
          <Link to={val.link}>{val.name}</Link>
        </li>
      )
    })

    menuMobile = (
      <div className="header-menu-mobile">
        <input type="checkbox" id={"overlay-input"} />
        <label htmlFor={"overlay-input"} id="overlay-button">
          <span></span>
        </label>
        <div id="overlay">
          <ul>{menuMobile}</ul>
        </div>
      </div>
    )
  }

  return (
    <>
      {menu}
      {menuMobile}
    </>
  )
}

export default Menu
