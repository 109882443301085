import {graphql} from 'gatsby'
import { kebabCase, cloneDeep, has, find } from 'lodash'
//import {ccMenu} from './ccvars';
const ccvars = require( `./ccvars`);
const removeMd = require('remove-markdown');

export class PostMdl{ 
  constructor(props) {
    var {title='No Title', content='', image, tags, date} = props;
    this.title = title;
    this.date = date;
    this.content = content!=null ? content : '';
    this.excerpt = removeMd(this.content);
    this.excerpt = nChars(this.excerpt, 150)  + '...';
    
    this.tags = tags?tags:[];
    this.slug = kebabCase(this.title);
    this.image = image;
  }
  getCreatedAt(){
    var adate = new Date(this.date);
  	var datestr = adate.toLocaleDateString("es-MX");
    return datestr;
  }
  getTagList(){
    let r='';
    if(this.tags){
      this.tags.forEach(element => r+=element.name + ', ');
      r = r.slice(0,-2)
    }
    return r;
  }
}
export class TrickMdl extends PostMdl{
  constructor(props) {
    super(props);
    var {name, aka, videourl, level, skill, type} = props;
    this.name = name; this.aka= aka;
    this.videoThumb = null;
    this.videourl = '';
    var id = null;
    if(videourl)
      id = youtubeParser(videourl);
    if(id){
      this.videourl = `https://www.youtube.com/embed/${id}`;
      this.videoThumb = `https://img.youtube.com/vi/${id}/hqdefault.jpg`
    }
    this.skill=skill;
    this.level = level? level.name:'';
    this.type = type? type:'';
  }
  getInfoArr(){
    return [
      {
        label: 'Nombre',
        value: this.name,
        link: ''
      },
      {
        label: 'Otros Nombres',
        value: this.aka,
        link: ''
      },
      {
        label: 'Disciplina',
        value: this.skill,
        link: `wiki-circo/${this.skill}`
      },
      {
        label: 'Dificultad',
        value: this.level,
        link: `wiki-circo/${this.skill}/nivel/${this.level}`
      }

    ]
  }
}
export class DuoPoseMdl extends TrickMdl{
  constructor(props) {
    super(props);
    var {numPeople, transitions=[]} = props;
    this.numPeople = numPeople;
    this.transitions = transitions;
  }
  getInfoArr(){
    var arr = super.getInfoArr();
    arr.push({
      label: 'Num Personas',
      value: this.numPeople,
      link: ''
    },
    {
      label: 'Tipo',
      value: this.type,
      link: ''
    })
    return arr;
  }
}

export function getNodes(obj){
  return obj[Object.keys(obj)[0]].edges;
}

export function getCCMenu(location){
  var menu = cloneDeep(ccvars.ccMenu);
  var res = null;
  if(has(location,'pathname') && location.pathname.indexOf('wiki-circo')>=0){
    res = find(menu, (o)=>{
      return o.link.indexOf('wiki-circo') >= 0
    })
    res.selected = true;
  }else{
    res = find(menu, {link:'/'})
    res.selected = true;
  }
  return menu;
}

export var getBreadCrumb = (location) =>{
  var split = location.pathname.split('/');
  var prefix = split[1]?split[1]:'';
  var skill = split[2]?split[2]:'';
  var level = split[3]?split[3]:'';
  level = isNaN(level)?level:''
  skill = isNaN(skill)?skill:''
  var cat = `${skill} ${level}`;
  if(!skill && !level && prefix==='wiki-circo')
    cat = 'Todos'
  return cat
}
export var getArgFromUrl = (location,property='') =>{
  if(!has(location,'pathname') ) return;
  var split = location.pathname.split('/');
  var tagindex = split.findIndex(elem=>elem===property)
  var res = tagindex>=0 ? split[tagindex+1]:''
  return res
}

/**
 * Returns the text from a HTML string
 * 
 * @param {html} String The html string
 */
export function stripHtml(html){
  return html.replace(/(<([^>]+)>)/ig,"");
    /*// Create a new div element
    if(typeof window !== `undefined`) return html;
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";*/
}
export function nChars(str, n){
  var trimmed = str;
  //Trim and re-trim only when necessary (prevent re-trim when string is shorted than maxLength, it causes last word cut) 
  if(str.length > n){
    //trim the string to the maximum length
    trimmed = str.substr(0, n);
    //re-trim if we are in the middle of a word and 
    trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(" ")))
  }
  return trimmed;
  //return str.replace(/^(.{1}[^\s]*).*/, "$1");
}

export function youtubeParser(url){
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
}


/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject({ a: 1, b: { c: 2 } })
 * Returns:
 *   { a: 1, c: 2}
 */
export var flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' &&
        obj[key] !== null &&
        Object.prototype.toString.call(obj[key]) !== '[object Array]') {
      Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export const blogItemFragment = graphql`
  fragment BlogItem on mongodbCuriosowebPosts {
    title
    content
    createdAt
    image{
     url
    }
    tags {
     name
    }
  }
`
export const duoBlogItemFragment = graphql`
  fragment DuoBlogItem on mongodbCuriosowebDuoPoses {
    title
    image{
      url
    }
    tags{
      name
    }
    createdAt
    content
    aka
    name
    numPeople
    type
    basePos {
      name
    }
    flyerPos {
      name
    }
  }
`