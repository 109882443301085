import React from "react"
import "../styles/footer.scss"

const Footer = () => (
  <footer className="footer">
    <a href="https://curiosocirco.com">
      © {new Date().getFullYear()}, Curioso Circo
    </a>
  </footer>
)

export default Footer
