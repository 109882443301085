import PropTypes from "prop-types"
import React from "react"
import logo from "../images/curiosocirco-solo-ojos.png"
import Menu from "./Menu"
import "../styles/header.scss"
import { getCCMenu, getArgFromUrl } from "/src/cchelper"
import { Link } from "gatsby"

const Header = ({ siteTitle, menuData, location }) => {
  var menu = getCCMenu(location)
  var breadcrumb =
    getArgFromUrl(location, "tag") + getArgFromUrl(location, "nivel")
  breadcrumb = breadcrumb ? breadcrumb : ""

  return (
    <header className="header">
      <Link to={"/"} className="logo">
        <img src={logo} alt="" />
      </Link>
      <Menu
        items={menu}
        addMobile={false}
        className={"curioso-wiki-menu"}
      ></Menu>
      <Menu items={menuData} className={"main-menu"}></Menu>
      <div className="breadcrumb">{breadcrumb}</div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
export default Header
